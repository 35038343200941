<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="locationEditForm"
      #default="{invalid}"
    >
      <b-form class="mt-2 flex-form">
        <b-card-body class="padding-bottom-normal">
          <b-row v-if="formData">
            <b-col sm="12">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Campus Name"
                label-for="campus_name"
              >
                <validation-provider
                  #default="{ errors }"
                  ref="name"
                  name="Campus name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="formData.name"
                    placeholder="Campus Name"
                    :state="errors.length > 0 ? false:null"
                    name="campus_name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Address"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  ref="address"
                  name="Campus address"
                  vid="address"
                  rules=""
                >
                  <b-form-input
                    v-model="formData.address"
                    :state="errors.length > 0 ? false:null"
                    name="address"
                    placeholder="Address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--            <b-col-->
            <!--              sm="12"-->
            <!--            >-->
            <!--              <b-form-group-->
            <!--                label-cols-sm="4"-->
            <!--                label-cols-lg="4"-->
            <!--                content-cols-sm-->
            <!--                content-cols-lg="8"-->
            <!--                label="Has kids check-in"-->
            <!--                label-for="kids_checkin"-->
            <!--              >-->
            <!--                <b-form-checkbox-->
            <!--                  v-model="formData.settings.has_kids_checkin"-->
            <!--                  checked="true"-->
            <!--                  name="kids_checkin"-->
            <!--                  switch-->
            <!--                  inline-->
            <!--                />-->
            <!--              </b-form-group>-->
            <!--            </b-col>-->

          </b-row>
        </b-card-body>
        <b-card-footer class="padding-0">
          <b-row md="6">
            <b-col />
            <b-col
              class="d-flex justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2"
                :disabled="invalid || isSubmitting"
                @click="submitForm"
              >
                Save changes
              </b-button>
            </b-col>
          </b-row>

        </b-card-footer>

      </b-form>
    </validation-observer>
  </b-card>

</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import { cloneDeep } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { handleValidationError } from '@/utils/helpers'
import store from '@/store'

export default {
  components: {
    BCard,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormCheckbox,
    BCardBody,
    BCardFooter,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    initialFormData: Object,
    onFormSaved: Function,
    onLocationChanged: { type: Function, required: false },
  },
  data() {
    return {
      isSubmitting: false,
      // this is important
      required,
      formData: cloneDeep(this.initialFormData),
      originalFormData: cloneDeep(this.initialFormData),
      // formData: this.initialFormData // bad: instant store change
      // formData: {...this.initialFormData} // bad: shallow copy, settings will still change parent and then store
    }
  },
  watch: {
    formData: {
      deep: true,
      handler(newFormData) {
        if (this.onLocationChanged) {
          this.onLocationChanged(newFormData)
        }
      },
    },
  },
  methods: {
    async submitForm() {
      this.$refs.locationEditForm.validate()
        .then(async success => {
          if (success) {
            this.isSubmitting = true
            const data = {
              name: this.formData.name,
              address: this.formData.address,
              settings: {
                has_kids_checkin: this.formData.settings.has_kids_checkin,
              },
            }

            let apiResponse
            let toastMessage
            try {
              if (this.formData.id) {
                apiResponse = await AccountService.updateLocation(this.formData.id, data)
                toastMessage = 'Campus saved'
              } else {
                apiResponse = await AccountService.createLocation(data)
                toastMessage = 'Campus created'
              }
              store.dispatch('auth/setAuthUser', apiResponse.data.data.user)

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: toastMessage,
                  variant: 'success',
                },
              })

              this.onFormSaved(apiResponse.data)
            } catch (error) {
              handleValidationError(error, this)
            } finally {
              this.isSubmitting = false
            }
          }
        })
    },
    resetForm() {
      this.formData = cloneDeep(this.originalFormData)
    },
  },
}
</script>

<style scoped>
  .padding-0 {
    padding: 0 !important;
  }

  .padding-bottom-normal {
    padding: 0 0 1.5rem !important;
  }

  .flex-form {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 1.5rem;
  }
</style>
